<script lang="ts">
  import { getAlertRootContext } from "./AlertRoot.svelte"

  let cssClass: string | undefined | null = undefined
  export { cssClass as class }
  export let level = 4

  const rootCtx = getAlertRootContext()
</script>

<div
  class="heading mb-double-extra-small flex items-center font-x-small-600-caps {cssClass}"
  role="heading"
  aria-level={level}
>
  <slot>
    {$rootCtx.variant}
  </slot>
</div>
