import type { SVGComponent } from "@tastyworks/icons"
import Icons from "@tastyworks/icons"
import { cva } from "class-variance-authority"
import type { VariantProps } from "class-variance-authority"
import Description from "./AlertDescription.svelte"
import Icon from "./AlertIcon.svelte"
import Root from "./AlertRoot.svelte"
import Title from "./AlertTitle.svelte"

export const Alert = {
  Description,
  Title,
  Root,
  Icon,
}

export const alertVariants = cva(
  "box-border rounded-lg border border-solid p-4",
  {
    variants: {
      variant: {
        confirmation:
          "border-alert-confirmation bg-alert-success text-alerts-confirmation",
        reminder:
          "border-alert-reminder bg-alert-reminder text-alerts-reminder",
        error: "border-alert-error bg-alert-error text-alerts-error",
        information:
          "border-alert-information bg-alert-information text-alerts-information",
        pending:
          "border-alert-pending bg-alert-order-working text-alerts-pending",
        info: "text-alerts-info border-alert-info bg-alert-info",
      },
      size: {
        fit: ["w-fit"],
        full: ["w-full"],
      },
    },
    defaultVariants: {
      variant: "confirmation",
    },
  }
)

export const alertIconVariants = cva(
  "w-6 shrink-0 text-general-secondary-label",
  {
    variants: {
      variant: {
        confirmation: "text-icon-general-confirmation",
        reminder: "text-icon-general-reminder",
        error: "text-icon-general-error",
        information: "text-icon-general-info",
        pending: "text-icon-general-pending",
        info: "text-icon-general-info",
      },
    },
    defaultVariants: {
      variant: "confirmation",
    },
  }
)

export function renderIconByVariant(
  variant: VariantProps<typeof alertVariants>["variant"]
): SVGComponent | undefined {
  switch (variant) {
    case "confirmation":
      return Icons.checkmark
    case "error":
      return Icons.cancelled
    case "info":
    case "information":
      return Icons.info
    case "reminder":
      return Icons.notice
    case "pending":
      return Icons.pending
  }
}
