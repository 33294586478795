<script lang="ts">
  import { onMount } from "svelte"

  export let sitekey = ""

  window.onCaptchaLoaded = function () {
    window._recaptchaClientId = window.grecaptcha.render("recaptcha", {
      sitekey,
      badge: "bottomleft",
      size: "invisible",
    })
  }

  onMount(() => {
    const recaptchaId = "recaptcha"
    let recaptchaDiv = document.getElementById(recaptchaId)
    if (!recaptchaDiv) {
      recaptchaDiv = document.createElement("div")
      recaptchaDiv.id = recaptchaId
      document.body.appendChild(recaptchaDiv)
    }
    recaptchaDiv!.style.display = "block"

    return () => {
      window.grecaptcha.reset(window._recaptchaClientId)
      recaptchaDiv!.style.display = "none"
    }
  })
</script>

<svelte:head>
  <script
    data-testid="recaptcha-script"
    type="text/javascript"
    src="https://www.google.com/recaptcha/api.js?onload=onCaptchaLoaded&render=explicit"
    async
    defer
  ></script>
</svelte:head>
